<script setup>
import { onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
import DeviceService from '@/apps/dailyTech/inventario-ti/services/devices.service'
import Icon from '@/components/Icon/index.vue'
import Swal from 'sweetalert2'

const toast = useToast()
const _DeviceService = ref(new DeviceService())
const modalDevice = ref(false)
const listDevices = ref([])
const device = ref({})
const submitted = ref(false)
const isEdit = ref(false)
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Obtener los tipos de dispositivos
const fetchDevices = async () => {
  try {
    const { status, data } = await _DeviceService.value.getDevices()
    if (status === 200) {
      listDevices.value = data
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Abrir el modal
const openModalDevice = (item) => {
  modalDevice.value = true
  submitted.value = false

  if (item) {
    device.value = { ...item }
    isEdit.value = true
  } else {
    device.value = {
      id: null,
      devicename: '',
      icon: 'material-symbols:devices-off-outline-rounded',
      status: true
    }
    isEdit.value = false
  }
}

// Cerrar el modal
const hideModalDevice = () => {
  modalDevice.value = false
  submitted.value = false
}

// Crear un dispositivo
const createDevice = async () => {
  try {
    // Validar que el dispositivo tenga un nombre
    if (!device.value.devicename) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre es requerido', life: 3000 })
      return
    }
    const { status } = await _DeviceService.value.createDevice(device.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Dispositivo creado exitosamente', life: 3000 })
      await fetchDevices()
      hideModalDevice()
      modalDevice.value = false
      device.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Actualizar un dispositivo
const updateDevice = async () => {
  try {
    // Validar que el dispositivo tenga un id y nombre
    if (!device.value.id || !device.value.devicename) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre es requerido', life: 3000 })
      return
    }

    const { status } = await _DeviceService.value.editDevice(device.value.id, device.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Dispositivo actualizado exitosamente', life: 3000 })
      await fetchDevices()
      hideModalDevice()
      modalDevice.value = false
      device.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Guardar o actualizar un dispositivo
const onSubmit = async () => {
  submitted.value = true
  if (isEdit.value && device.value.id) {
    await updateDevice()
  } else {
    await createDevice()
  }
}

// Eliminar un dispositivo
const deleteDevice = async (id) => {
  try {
    if (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Desea eliminar el dispositivo?, esta acción no se puede revertir.',
        showDenyButton: true,
        denyButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          _DeviceService.value.deleteDevice(id).then(({ status }) => {
            if (status !== 200) return

            toast.add({ severity: 'success', summary: 'Exito', detail: 'Dispositivo eliminado exitosamente', life: 3000 })
            fetchDevices()
            hideModalDevice()
            modalDevice.value = false
            device.value = {}
          }).catch((err) => {
            console.error(err.message)
            if (err.response.status) {
              toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el dispositivo', life: 3000 })
            }
          })
        }
      })
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el dispositivo', life: 3000 })
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Hook onMounted
onMounted(async () => {
  await fetchDevices()
})
</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 py-2 px-4">
    <template #start>
      <Button label="Regresar" icon="pi pi-arrow-left" class="p-button-sm" @click="$router.push({ name: 'daily-tech.inventario-ti.configuracion'})"/>
    </template>
    <template #end>
      <Button
        label="Agregar"
        icon="pi pi-plus"
        size="small"
        severity="success"
        @click="openModalDevice(null)"
      />
    </template>
  </Toolbar>

  <!-- Listado de los dispositivos -->
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="listDevices"
      class="p-datatable-sm p-4 text-sm"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      sortField="id"
      :sortOrder="-1"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25,50]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de dispositivos</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <template #empty>
        <div class="flex flex-col items-center justify-center" style="height: 500px">
          <Icon icon="flat-color-icons:multiple-devices" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
          <span class="text-gray-400 text-2xl">No se encontraron registros</span>
          <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
        </div>
      </template>
      <template #loading> Cargando la información, por favor espere... </template>
      <Column field="id" header="Id" :sortable="true"></Column>
      <Column field="devicename" header="Nombre" :sortable="true"></Column>
      <Column field="icon" header="Icono">
        <template #body="{ data }">
          <Icon :icon="data.icon" style="font-size: 1.5rem;" />
        </template>
      </Column>
      <Column field="status" header="Estado" :sortable="true">
        <template #body="{ data }">
          <span :class="'bank-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.status === true ? 'bg-green-700' : 'bg-red-700')">{{data.status === true ? 'Activo' : 'Inactivo'}}</span>
        </template>
      </Column>
      <Column :exportable="false" header="Acciones" style="text-align: center">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="openModalDevice(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteDevice(data.id)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- Modal formulario dispositivos -->
  <Dialog
    v-model:visible="modalDevice"
    :style="{width: '450px'}"
    :modal="true"
    class="p-fluid"
    :pt="{
      header: { style: 'padding: 1rem;' }
    }"
  >
    <template #header>
      <h3 :class="{'text-yellow-700': isEdit, 'text-green-700': !isEdit}" class="font-bold text-xl">{{isEdit ? 'Editar' : 'Crear'}} dispositivo</h3>
    </template>
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText
        id="name"
        v-model="device.devicename"
        required="true"
        autofocus
        :class="{'p-invalid': submitted && !device.devicename}"
        class="p-fluid"
      />
      <small class="p-error" v-if="submitted && !device.devicename">El nombre es requerido.</small>
    </div>
    <!-- Icono -->
    <div class="p-field">
      <label for="icon">Icono</label>
      <div class="flex items-center gap-2">
        <InputText
          id="icon"
          v-model="device.icon"
          class="p-fluid"
          autocomplete="off"
        />
        <!-- Visualizar icon en tiempo real -->
        <div class="bg-gray-200 p-2 rounded flex justify-center items-center" style="width: 38px; height: 38px">
          <Icon :icon="device.icon" style="font-size: 2rem;" />
        </div>
      </div>
      <span class="text-xs font-semibold">Buscar mas iconos en <a href="https://icon-sets.iconify.design/" target="_blank" class="text-blue-500">Iconify</a></span>
    </div>
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="device.status" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideModalDevice"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="onSubmit" />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
